.custom-button {
  margin-top: 0rem;
  background-color: #36454F;
  border: 3px solid #004077;
  color: white;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #004077 !important;
  border-color: #004077 !important;
  color: white !important;
}

.custom-button-disabled {
  background-color: #7c7c7c !important;
  border-color: #A9A9A9 !important;
  color: #000000 !important;
  pointer-events: none;
  opacity: 0.7;
}

.form-transition-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.8s ease, opacity 0.8s ease;
}

.form-transition-enter-active {
  max-height: 500px;
  opacity: 1;
}

.form-transition-exit {
  max-height: 500px;
  opacity: 1;
}

.form-transition-exit-active {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.8s ease, opacity 0.8s ease;
}

.custom-pagination {
  justify-content: center;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-pagination .page-item {
  margin: 0 5px;
}

.custom-pagination .page-link {
  background-color: #36454F;
  border: 3px solid #004077;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.custom-pagination .page-link:hover {
  background-color: #004077 !important;
  border-color: #004077 !important;
}

.custom-pagination .page-link.active {
  background-color: #004077 !important;
  border-color: #004077 !important;
  cursor: default;
}

.custom-hover-button:hover .hover-icon {
  color: #000000;
  transition: color 0.5s ease;
}

.custom-hover-button {
  transition: background-color 0.3s ease;
}

.custom-hover-button:hover {
  background-color: #2e3b44;
}