.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

form {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  background-color: #36454F;
}

.img-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
}

.img-logo a {
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
}

.img-logo img {
  max-width: 80%;
  display: block;
}

.form-group {
  width: 100%;
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  text-align: center;
  font-weight: bold;
}

.form-control {
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid #ced4da;
  width: 100%;
}

.btn-primary {
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: none;
  background-color: #000000;
  color: white;
  margin-top: 1rem;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #004077;
}

.errorModalTitle {
  text-align: center !important;
}

@media (max-width: 768px) {
  .login-container {
    padding: 10px;
  }

  form {
    padding: 15px;
  }
}

/* Apply the same styles as the change password modal */
.custom-modal-header {
  background-color: #2e3b44; /* Match the modal's body background */
  color: #ffffff; /* Ensure text contrast */
  border-bottom: none; /* Remove the header's bottom border */
}

.custom-modal-body {
  background-color: #2e3b44; /* Modal body background */
  color: #ffffff; /* Text color */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure full height for vertical centering */
  padding: 20px; /* Add some spacing inside the modal */
  border-top: none; /* Remove any border above the modal body */
}

.custom-modal-body form {
  background-color: transparent; /* Make the form background transparent */
  border: none; /* Remove any borders */
  padding: 0; /* Remove default padding */
  box-shadow: none; /* Remove any shadows */
}

.custom-modal-body .form-control {
  background-color: #ffffff; /* Inputs should remain white for visibility */
  color: #000000; /* Text color for input fields */
  border: 1px solid #ced4da; /* Standard input border */
}

.custom-modal-body .btn {
  background-color: #000000; /* Match buttons to the modal's theme */
  color: #ffffff; /* Text color for buttons */
}

.custom-modal-body .btn:hover {
  background-color: #333333; /* Slightly darker hover effect */
}
