/* Layout.css */
.navbar {
  background-color: #36454F;
  color: white;
}

.navbar a,
.navbar a:hover,
.navbar a:active {
  color: white;
}

.sidebar {
  background-color: #36454F;
  color: white;
  height: 100vh; /* Full height */
  position: fixed; /* Fix the sidebar to the side */
  overflow: hidden; /* Prevent scrolling */
  width: 16.6667%; /* Width equivalent to 2 columns */
}

.custom-card {
  background-color: transparent; /* Set background color of the card to transparent */
  border: none; /* Remove border */
}

.custom-link {
  color: white;
  transition: color 0.2s, background-color 0.2s;
}

.custom-link:hover {
  color: lightblue;
}

.custom-link-active {
  color: #004077;
}

#page-content-wrapper {
  margin-left: 16.6667%;
  overflow-y: auto;
  height: 100vh;
  padding: 20px;
}

.mt-auto {
  margin-top: auto;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.custom-modal-header {
  background-color: #2e3b44;
  color: #ffffff;
  border-bottom: none;
}

.custom-modal-header .btn-close {
  filter: invert(100%);
}

.custom-modal-body {
  width: 100%;
  background-color: #2e3b44;
  color: #ffffff;
  border-top: none;
  display: flex;
  height: 100%;
}

.modal-content {
  background-color: #2e3b44;
  border-radius: 10px;
  border: none;
}

.custom-modal-body form {
  width: 100%;
  background-color: transparent !important; /* Force the form to be transparent */
  border: none; /* Remove any unintended border */
  padding: 0; /* Ensure no extra padding around the form */
  box-shadow: none; /* Remove any shadow effect */
}

.text-muted, .modal-title, .modal-body, .h1, .h2, .h3, .h4 {
  color: white !important;
}

.modal-content {
  border: solid;
  border-color: gray;
  border-width: 1px;
}

.modal-input {
  width: 300px;
  margin-bottom: 20px;
}

.custom-table {
  margin-top: 10px;
  border-radius: 10px !important;
  overflow: hidden !important;
  border-collapse: collapse !important;
  border-color: #000000;
}

.custom-table-header-mini {
  background-color: #0d1b25 !important;
  font-size: 20px !important;
}

.custom-table-header-mini {
  background-color: #0d1b25 !important;
  font-size: 14px !important;
}

.custom-table-body-mini {
  background-color: #0d1b25 !important;
  font-size: 14px !important;
}

.nav-scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  direction: rtl;
}

.nav-scrollable > * {
  direction: ltr;
}

.nav-scrollable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.nav-scrollable::-webkit-scrollbar-track {
  background: #2e3b44;
  border-radius: 10px;
}

.nav-scrollable::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #2e3b44;
}

.nav-scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #6c7a8a;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}